
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all .5s ease;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  transition: all .5s ease;
  border: none;
  background: #4a80be;
  border-radius: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 10px 40px;
  font-weight: 400;
  font-size: 17px;
  cursor: pointer;
  color: #fff;
  float: right;
  font-weight: 400;


  margin:10px 0;
}

.wrapper {
  box-sizing: border-box;
  padding: 0 20px;
  margin: auto;
}

.wrapper a {
  display: inline-block;
  margin: 5px;
}

.wrapper a:first-child {
  margin-left: 0;
}



.slider-wrapper {
  position: relative;
  height: 70vh;
  overflow: hidden;
  
}







.slide::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    /* background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.9)));
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.9)); */
    bottom: 0;
    left: 0;
}

.previousButton {
		position: absolute;
		top: 40%;
    transform: translateY(-50%);
		z-index: 10;
		background: url('../images/leftslider.svg') no-repeat center center / 40px;
		width: 80px;
		height: 80px;
		text-indent: -9999px;
    cursor: pointer;
    background-color: rgba(225, 229, 234,0.54);
    transition: 0.3s;

}


.nextButton {
  position: absolute;
  top: 40%;
  z-index: 10;
  background: url('../images/leftslider.svg') no-repeat center center / 40px;
  width: 80px;
  height: 80px;
  text-indent: -9999px;
  cursor: pointer;
  background-color: rgba(225, 229, 234,0.54);
  transform: rotate(-90deg);
  transition: 0.3s;

}





.previousButton {
  left: 0;
   transform: rotate(90deg);

}

.previousButton:hover {
  left: -10px;
}

.nextButton {
  right: 0;
}

.nextButton:hover {
  right: -10px;
}

.slider-content {
  text-align: center;
}


.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}



@media (max-width:414px) {

  .slider-content .inner {

  padding: 0 20px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
   transform: translate(-50%, -50%);
}
}





.slider-content .inner button{
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}

.slider-content .inner h1 {
  font-family: 'Cairo-Black';
  margin: 20px auto;
  color: #11192d;
  font-size: 27px;
  text-align: right;
}

.slider-content .inner p {
  color: #292b2c;
  font-size: 22px;
  line-height: 1.5;
  margin: 20px 0;
  /* max-width: 640px; */
  text-align: right;
  font-family: 'Cairo-Light';

}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #FFFFFF;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #FFFFFF;
  font-size: 14px;
  display: block;
}

@media (max-height: 500px) {
  .slider-wrapper, .slide {
    height: calc(100vh - 75px);
  }    
}

@media (min-width: 640px) {
  .slider-wrapper, .slide {
    height:450px ;
  }    
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }    
}



@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}



@media (max-width: 414px) {
  .slider-content .inner h1 {
    font-size: 18px;
    text-align: center;

  }    
  button{
    padding:5px 20px;
  }

  .slider-content .inner p {
    font-size: 15px;
    text-align: center;

  }

  .nextButton, .previousButton{
    width: 50px;
    height: 50px;
  }

  .slider-content .inner{
    top: 50%;

  }
}



/* Menu Mobile*/


  /* Position and sizing of burger button */
  .bm-burger-button {
    position: absolute;
    width: 36px;
    height: 20px;
    right: 26px;
    top: 54px;
    z-index: 99999 !important;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    height: 10% !important;
    background: #10182d;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #4a7fbd;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    z-index: 99999999 !important;
    top:0;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #11192d;
    overflow: hidden !important;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 30px;
    overflow-y: scroll;
    margin-top: 100px;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    top: 0px;
    background: rgba(0, 0, 0, 0.3);
  }
  .bm-item {
    outline: 0;
  }

/* MenuMobile */
div#RelativeNavabr {
  position: relative;
}


@font-face {
  font-family: 'Cairo-Black'; 
  src: url('./assets/fonts/Cairo/Cairo-Black.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'Cairo-Bold';
  src: url('./assets/fonts/Cairo/Cairo-Bold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Cairo-Light';
  src: url('./assets/fonts/Cairo/Cairo-Light.ttf') format('truetype');
  font-style: normal;
}


@font-face {
  font-family: 'Cairo-Regular';
  src: url('./assets/fonts/Cairo/Cairo-Regular.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'Cairo-SemiBold';
  src: url('./assets/fonts/Cairo/Cairo-SemiBold.ttf') format('truetype');
  font-style: normal;
}


.App {
  font-family: 'Cairo-Regular'; 
  direction: rtl;
  overflow-x: hidden;

}


body {
  font-family: 'Cairo-Regular'; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;

}




.ant-modal-footer{
  display: none !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
  font-size: 20px;

}


.ant-radio-inner {
border-color: #10182d;
height: 20px;
width: 20px;
    background: white;
    border: 1px solid;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

@media (min-width:768px) {
  .container {
      width: 750px
  }
  .ant-modal {
  width: 650px !important;
  border-radius: 0;
  z-index: 999;

}
}

@media (min-width:992px) {
  .container {
      width: 970px
  }
}

@media (min-width:1200px) {
  .container {
      width: 1170px
  }
}


.ant-row {
  clear: both;
}


.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled){
  border-color: transparent;
}

.text_white{
  text-align: left;
}

@media (max-width:414px){
  .ant-input-search-icon {
    width: 30px;
    height: 25px;
  }

  .text_white{
    text-align: right;
  }

  }
  .ant-radio-inner::after {
    position: absolute;
    top: 7px;
    left: 0;
   
    width: 20px;
    height: 20px;
    background-color: red;
    
    border-radius: 8px;
  
}
.ant-radio-inner::after {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 11px;
  height: 11px;
  background-color: #10182d;
  border-radius: 50px;
}
.ant-checkbox-checked .ant-checkbox-inner::after  {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}



.ant-input-search-icon {
  color: transparent;
  font-size: 16px;
  background: #4a80be;
  width: 40px;
  height: 30px;
  border-radius: 50px;
  line-height: 38px;
  background: url('./assets/images/search.svg');
  background-repeat: no-repeat;

}

.ant-input-search-icon:hover {
  color: transparent;
}






.shop_banner2:last-child {
margin-bottom: 0;
}
.el_banner2 .el_img img {
max-width: 165px;
width: 100%;
}
  .el_banner2 .el_title span {

    font-family: 'Cairo-Bold';
    font-size: 20px;
  }
  

.ant-input:focus {
    border-color: transparent;
    border-right-width: 1px !important;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: none;
}








.ant-tooltip-inner {

  direction: rtl;
}

.ant-message-notice-content{
font-size: 18px;
  border-radius: 0;

}





.ant-modal-wrap {

  z-index: 99999;
}




.ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header{
  border-radius: 0;
  font-size: 18px;

}



.for-mobile{


    height: 100%;
    max-width: 100%;
    width: 100%;
    border-radius: 0;
    top: 0;
    padding: 0;
    direction: rtl;
    margin: 0;
  
}

.ant-spin-spinning {
  text-align: center;
margin: 0px auto;
  display: block;
  clear: both;

}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  border-radius: 0px;
}

/* button.image-gallery-thumbnail:focus {
  border: 3px solid red !important;
} */
.image-gallery-thumbnail{
  border: 0;
}


.image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right{
  width: 75px;

}

.image-gallery-thumbnail {
  width: 70px;

}

.image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
  display: inline-block;
  width: calc(100% - 90px);
}
@media (max-width:767px){


.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 0;
  margin-top: 0px;
}

}